<template>
  <div class="fluid" id="hierarchyList">
    <div>
      <div class="hierarchy-list-div">
        <v-card class="px-3 py-2">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
            >
              <h6 class="indigo--text" style="font-weight:bold;margin:auto;">
                HIRARKI JABATAN
              </h6>
            </v-col>
            <v-col cols="12">
              <v-toolbar
                class="hierarchy-list-toolbar-1"
                flat
                style="height: 60px; border-radius: 10px 10px 0 0"
              >
                <div style="width: 140px" class="me-5">
                  <v-select
                    :disabled="selectedData.length === 0"
                    label="Action"
                    style="
                      position: relative;
                      top: 15px;
                      font-size: 12px;
                    "
                    v-model="actionValue"
                    :items="[
                      { id: 0, name: '' },
                      { id: 1, name: 'Delete' }
                    ]"
                    item-text="name"
                    item-value="id"
                    outlined
                    return-id
                    dense
                    @change="action"
                  >
                  </v-select>
                </div>
                <v-dialog v-model="dialog" width="500" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      elevation="1"
                      color="indigo"
                      class="indigo--text font-weight-bold me-5"
                      style="font-size:12px;"
                      @click="addData"
                      :disabled="loading"
                    >
                      Tambah Data
                    </v-btn>
                  </template>

                  <v-form
                    :disabled="loading"
                    ref="entryForm"
                    @submit.prevent="submit"
                    style="position: relative;"
                  >
                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Tambah Data
                      </v-card-title>

                      <v-card-text class="d-flex justify-center align-center">
                        <v-row
                          cols="12"
                          style="padding: 0 10px; position:relative; top:20px;"
                          no-gutters
                        >
                          <v-col cols="12" style="padding: 0 5px;">
                            <p
                              class="text-left"
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Nama
                            </p>
                            <v-autocomplete
                              :items="dropdown.employeeData"
                              item-text="name"
                              item-value="id"
                              return-id
                              outlined
                              dense
                              hide-details
                              hide-no-data
                              style="margin: 0;height: 55px;"
                              clearable
                              v-model="form.employee_id"
                              :rules="employeeRules"
                              :search-input.sync="employeeSearch"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" style="padding: 0 5px;">
                            <p
                              class="text-left"
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Department/ Section
                            </p>
                            <v-autocomplete
                              :items="dropdown.department"
                              item-text="name"
                              item-value="id"
                              return-id
                              outlined
                              dense
                              style="margin: 0;height: 55px;"
                              v-model="form.department_id"
                              :rules="departmentRules"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" style="padding: 0 5px;">
                            <p
                              class="text-left"
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Jabatan
                            </p>
                            <v-autocomplete
                              :items="dropdown.position"
                              item-text="name"
                              item-value="id"
                              return-id
                              outlined
                              dense
                              style="margin: 0;height: 55px;"
                              v-model="form.level_id"
                              :rules="positionRules"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" style="padding: 0 5px;">
                            <p
                              class="text-left"
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              No. SK
                            </p>
                            <v-text-field
                              v-model="form.sk_no"
                              outlined
                              dense
                              :rules="skNoRules"
                              style="height: 55px;"
                            />
                          </v-col>
                          <v-col cols="12" style="padding: 0 5px;">
                            <p
                              class="text-left"
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Tgl. SK
                            </p>
                            <v-text-field
                              v-model="form.sk_date"
                              outlined
                              dense
                              type="date"
                              name="date"
                              step="1"
                              :rules="skDateRules"
                              style="height: 55px;"
                            />
                          </v-col>
                          <v-col
                            v-if="form.act == 'update'"
                            cols="12"
                            style="padding: 0 5px;"
                          >
                            <p
                              class="text-left"
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Atasan
                            </p>
                            <v-text-field
                              readonly
                              :value="boss"
                              dense
                              style="height: 55px;"
                            />
                          </v-col>
                          <!-- <v-col cols="12" md="6" style="padding: 0 5px;">
                            <p
                              class="text-left"
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Status
                            </p>
                            <v-autocomplete
                              :items="dropdown.status"
                              item-text="name"
                              item-value="id"
                              return-object
                              outlined
                              dense
                              style="margin: 0"
                              v-model="form.status"
                            ></v-autocomplete>
                          </v-col> -->
                        </v-row>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn
                          color="error"
                          text
                          outlined
                          @click="close"
                          :loading="loading"
                        >
                          Batal
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          type="submit"
                          text
                          outlined
                          :loading="loading"
                        >
                          Simpan
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
                <div
                  style="width: 400px; margin-left:auto;"
                  class="hierarchy-list-toolbar-div"
                >
                  <v-text-field
                    v-model="paramAPI.keyword"
                    label="cari nama"
                    type="search"
                    outlined
                    dense
                    style="position: relative; height:40px;"
                    append-icon="mdi-magnify"
                    @keyup.enter="searchEnter"
                    :disabled="loading"
                  >
                  </v-text-field>
                </div>
              </v-toolbar>
              <v-toolbar
                class="hierarchy-list-toolbar-2"
                flat
                style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
              >
                <v-text-field
                  v-model="paramAPI.keyword"
                  label="Cari nama"
                  type="search"
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  @keyup.enter="searchEnter"
                  :disabled="loading"
                  style="position: relative; top: 15px; width: 10px"
                ></v-text-field>
              </v-toolbar>
            </v-col>

            <v-col cols="12">
              <div
                cols="12"
                style="height:15px;"
                class="d-flex justify-start align-center"
              >
                <router-link
                  class="indigo--text"
                  style="margin-left:20px; font-size:14px;"
                  :to="'/hr/position_hierarchy'"
                >
                  Lihat struktur
                </router-link>
                <v-icon small>mdi-chevron-right</v-icon>
              </div>
              <v-data-table
                mobile-breakpoint="0"
                fixed-header
                height="58vh"
                v-model="selectedData"
                :headers="headers"
                style="cursor: pointer;"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                show-select
                :single-select="true"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                @update:page="updatePage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.employee`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.employee !== null ? item.employee.name : 'NULL' }}
                  </div>
                </template>
                <!-- <template v-slot:[`item.status`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.status !== null ? item.status.name : 'NULL' }}
                  </div>
                </template> -->
                <template v-slot:[`item.department`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{
                      item.department !== null ? item.department.name : 'NULL'
                    }}
                  </div>
                </template>
                <template v-slot:[`item.level`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.level !== null ? item.level.name : 'NULL' }}
                  </div>
                </template>
                <template v-slot:[`item.sk_date`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ convertDate(item.sk_date) }}
                  </div>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ convertDate(item.created_at) }}
                  </div>
                </template>
                <template v-slot:[`item.updated_at`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ convertDate(item.updated_at) }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import buildType from '../../../../services/buildType'
// import XLSX from 'xlsx'
export default {
  name: 'hierarchyposition',
  data: () => ({
    dialog: false,
    hrsApi: buildType.apiURL('hrs'),
    build: process.env.VUE_APP_BUILD_TYPE,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      status: '',
      sortBy: 'name',
      sortType: 'asc',
      company_id: 0,
      department_id: 0,
      position_id: 0,
      itemsPerPage: 10,
      year: ''
    },
    totalData: 0,

    options: {
      page: 1,
      itemsPerPage: 10,
      sortDesc: []
    },
    actionValue: 0,
    headers: [
      {
        text: 'NIK',
        value: 'employee.nik',
        align: 'left',
        sortable: false
      },
      {
        text: 'Nama',
        value: 'employee',
        align: 'left',
        sortable: false
      },
      {
        text: 'Department',
        value: 'department',
        align: 'left',
        sortable: false
      },
      {
        text: 'Jabatan',
        value: 'level',
        align: 'left',
        sortable: false
      },
      {
        text: 'No. SK',
        value: 'sk_no',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tgl. SK',
        value: 'sk_date',
        align: 'left',
        sortable: false
      },
      {
        text: 'Dibuat pada',
        value: 'created_at',
        align: 'left',
        sortable: false
      },
      {
        text: 'Diupdate pada',
        value: 'updated_at',
        align: 'left',
        sortable: false
      }
    ],
    dropdown: {
      status: [
        {
          name: 'Active',
          id: 1
        },
        {
          name: 'Unactive',
          id: 2
        }
      ],
      department: [],
      position: [],
      employeeData: []
    },
    employeeSearch: null,
    select: null,
    result: [],
    selectedData: [],
    loading: false,
    form: {
      act: 'add',
      id: '',
      sk_date: '',
      sk_no: '',
      employee_id: null,
      department_id: null,
      level_id: null
    },
    boss: '-',
    employeeRules: [],
    skNoRules: [],
    skDateRules: [],
    departmentRules: [],
    positionRules: []
  }),

  async mounted() {
    this.paramAPI.company_id = Number(
      this.getUserProfile.employee.company.plant_id
    )
    await this.getDataFromApi()
    this.setAction()
    this.initDropdown()
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  watch: {
    employeeSearch(val) {
      if (val !== '') {
        val && val !== this.select && this.querySelections(val)
      } else {
        this.select = null
        this.dropdown.employeeData = []
      }
    }
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    async initDropdown() {
      await this.dropdownDepartment()
      await this.dropdownPosition()
    },
    querySelections(v) {
      axios
        .get(`${this.hrsApi}employee/dropdown?keyword=${v}`)
        .then(res => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.employeeData = res.data.data)
          }
          return (this.dropdown.employeeData = [])
        })
        .catch(err => {
          this.dropdown.employeeData = []
          return console.log(err)
        })
    },
    close() {
      this.$refs.entryForm.reset()
      this.form = {
        act: 'add',
        id: '',
        sk_date: '',
        sk_no: '',
        employee: null,
        department: null,
        level: null
      }
      this.actionValue = 0
      this.selectedData = []
      this.dialog = false
      this.boss = '-'
    },
    action(event) {
      switch (event) {
        case 1:
          if (this.selectedData.length < 2) {
            this.delete()
          } else {
            this.showMsgDialog(
              'warning',
              'Maaf, untuk saat ini Anda hanya dapat menghapus 1 data pada satu waktu',
              false
            )
            setTimeout(() => {
              this.selectedData = []
              this.actionValue = null
            }, 200)
          }
          break
      }
    },
    searchEnter() {
      this.getDataFromApi()
    },
    setAction() {
      this.actionValue = 0
      this.selectedData = []
    },

    async getDataFromApi() {
      this.loading = true
      await this.initDataTable().then(data => {
        console.log(data)
        setTimeout(() => {
          this.result = data.data
          this.totalData = data.total_record
          this.loading = false
        }, 2000)
      })
    },
    async initDataTable() {
      return await new Promise(resolve => {
        axios
          .get(
            `${this.hrsApi}employee_department/list?keyword=${
              this.paramAPI.keyword
            }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
              this.paramAPI.limit
            }`
          )
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    addData() {
      this.form.act = 'add'
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },

    submit() {
      this.employeeRules = [v => !!v || 'Employee is required']
      this.departmentRules = [v => !!v || 'Department is required']
      this.positionRules = [v => !!v || 'Position is required']
      this.skNoRules = [v => !!v || 'SK number is required']
      this.skDateRules = [v => !!v || 'SK date is required']
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        act: this.form.act,
        sk_date: this.form.sk_date,
        sk_no: this.form.sk_no,
        employee_id: this.form.employee_id,
        department_id: this.form.department_id,
        level_id: this.form.level_id
      }
      if (this.form.act === 'update') {
        Object.assign(form, { id: this.form.id })
      }
      console.log(form)
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee_department/save`, form)
        .then(async res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            await this.getDataFromApi()
            this.close()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }

          this.loading = false
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },
    async rowClick(pItem) {
      this.form.act = 'update'
      this.form.id = pItem.id
      this.form.sk_date = this.changeDate(pItem.sk_date)
      this.form.sk_no = pItem.sk_no
      this.form.employee_id = pItem.employee.id
      this.form.department_id = pItem.department.id
      this.form.level_id = pItem.level.id
      this.employeeSearch = pItem.employee.name
      await axios
        .get(
          `${this.hrsApi}employee_department/direct_lead/${pItem.employee.id}`
        )
        .then(async res => {
          if (res.data.status_code !== '-99') {
            console.log(res.data.data[0])
            this.boss = res.data.data[0].employee_name
          }
        })
        .catch(err => {
          console.log(err)
        })

      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    async delete() {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async res => {
        if (res.isConfirmed) {
          this.loading = true
          await axios
            .delete(
              `${this.hrsApi}employee_department/delete/${this.selectedData[0].id}`
            )
            .then(async res => {
              this.setAction()
              this.showMsgDialog('success', res.data.status_msg, 'false')
              await this.getDataFromApi()
            })
            .catch(err => {
              console.log(err)
            })
          this.loading = false
        } else {
          this.actionValue = null
        }
      })
    },

    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString()
        return local
      }
    },
    changeDate(raw) {
      const selectedDate = new Date(raw)
      const nextDate = new Date(
        new Date(raw).setDate(selectedDate.getDate() + 1)
      )
      this.dateFilter = nextDate.toISOString().substr(0, 10)
      return this.dateFilter
    },
    async dropdownDepartment() {
      await axios
        .get(`${this.hrsApi}master/universal/department/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          this.dropdown.section = []
          return (this.dropdown.department = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },
    async dropdownPosition() {
      await axios
        .get(`${this.hrsApi}master/universal/employeelevel/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.position = res.data.data)
          }
          return (this.dropdown.position = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.position = [])
        })
    }
  }
}
</script>
<style lang="scss">
#hierarchyList {
  position: relative;
  .hierarchy-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .hierarchy-list-toolbar-1 {
      .hierarchy-list-toolbar-div {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .hierarchy-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #hierarchyList {
    .hierarchy-list-div {
      .hierarchy-list-toolbar-1 {
        .hierarchy-list-toolbar-div {
          display: none;
        }
      }
      .hierarchy-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
